import { useEffect, useState } from 'react';
import config from '@/tailwind.config';
const smScreenWidth = config?.theme?.screens?.sm?.replace('px', '') || '640';

export const useScreenWidth = () => {
  const [width, setWidth] = useState<number>(0);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return { width, isMobile: width < parseInt(smScreenWidth) };
};
