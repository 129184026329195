'use client';
import React, { useEffect, useRef, useState } from 'react';
import { useClientScroll } from '@/hooks/useClientScroll';
import { getIfSlideIsVisbile } from 'react-multi-carousel/lib/utils';

export const StreamText = ({
  speed = 20,
  content,
}: {
  speed: number;
  content?: string;
}) => {
  const [text, setText] = useState('');
  const [words, setWords] = useState<string[]>([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.5 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const setArticleText = async () => {
      const article =
        '#Bergischer HC stürzt weiter ab: Bittere 24:31-Niederlage gegen VfL Gummersbach trotz starkem Kampfgeist\n' +
        'In einem packenden Spiel musste sich der Bergische HC am Ende deutlich mit 24:31 gegen den VfL Gummersbach geschlagen geben. Für die Löwen eine herbe Enttäuschung vor den eigenen Fans. In der Tabelle bleibt der BHC auf Platz 17, während Gummersbach auf Rang 7 verweilt.\n' +
        '\n' +
        'Das Match begann sehr dynamisch mit einem offenen Schlagabtausch. Zweimal glichen die Löwen in den ersten vier Minuten aus und hielten dadurch den Anschluss. Doch Gummersbach hatte immer eine Antwort parat und ging mehrfach in Führung. Besonders Julian Köster glänzte mit insgesamt acht Toren als bester Gummersbacher Torschütze. \n';
      setWords(article.split(' '));
      setCurrentWordIndex(0);
    };
    setArticleText();
  }, []);

  useEffect(() => {
    let timeoutId: any = null;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        if (currentWordIndex < words.length) {
          setText((prevText) => prevText + ' ' + words[currentWordIndex]);
          setCurrentWordIndex(currentWordIndex + 1);
        }
      }, speed);
    }

    return () => clearTimeout(timeoutId);
  }, [isVisible, words, currentWordIndex]);

  return (
    <div ref={componentRef}>
      {text && (
        <div className="flex flex-col mt-4 w-full text-foreground">
          {text.split('\n').map((str, i) => {
            let textStyle = 'text-sm';
            if (
              str.trim().substring(0, 1) === '#' &&
              str.trim().substring(0, 2) !== '##'
            ) {
              textStyle = 'text-md font-bold mb-4';
              str = str.replace('#', '');
            }
            if (str.trim().substring(0, 2) === '##') {
              textStyle = 'text-sm font-bold';
              str = str.replace('##', '');
            }
            return (
              <p className={textStyle} key={i}>
                {str}
                <br />
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};
