const platformFeatures = [
  {
    title: 'Präzise Spielberichte auf Abruf!',
    description:
      'Erhalte sofortigen Zugriff auf hochqualitative Spielberichte mit nur einem Klick!\nErstelle einen Bericht unmittelbar nach dem Spielende und nutze ihn auf deiner Website. Nie war es einfacher über die aktuellsten Events in der Welt des Sports zu berichten.',
    mediaType: 'image',
    mediaSrc: '/assets/immediate-download.jpg',
    btnText: 'Jetzt entdecken',
  },
  {
    title: 'Qualität und inhaltliche Tiefe durch intelligente Technologie!',
    description:
      'Unsere Technologie ist darauf trainiert, hochwertige Spielberichte zu liefern, die nicht nur die grundlegenden Ereignisse des Spiels umfassen, sondern auch interessante Zusammenhänge bieten, die aus der Auswertung von Sportdaten resultieren und in die Spielberichte integriert werden.',
    mediaType: 'video',
    mediaSrc: '/assets/video/article-generation_no-bg-no-zoom.mp4',
    btnText: 'Jetzt entdecken',
  },
  {
    title: 'Eine Plattform für alle Sportarten!',
    description:
      'Die BlendLane Plattform ist eure Quelle für erstklassige Spielberichte in allen Sportarten. Mit einem breiten Spektrum an Berichterstattungsoptionen ermöglichen wir es euch, Content zu generieren, der eure Leser begeistert und eure Redaktion zu einem unverzichtbaren Anlaufpunkt für alle Sportfans macht. Werde Teil unserer dynamischen Plattform und entdecke die Möglichkeiten der Sportberichterstattung mit BlendLane!',
    mediaType: 'image',
    mediaSrc: '/assets/multiple-sports.jpg',
    btnText: 'Jetzt entdecken',
  },
  {
    title: 'Zeitersparnis durch automatisierte Berichterstellung!',
    description:
      'Unsere BlendLane Plattform generiert Spielberichte sofort nach einem Spiel. Keine aufwändige Arbeit mehr um Berichte manuell zu erstellen. BlendLane nutzt eine eigens trainierte, fortschrittliche künstliche Intelligenz, um qualitativ hochwertige Berichte in Sekundenschnelle zu erstellen.',
    mediaType: 'image',
    mediaSrc: '/assets/time-saved.jpg',
    btnText: 'Jetzt entdecken',
  },
  {
    title: 'Vielfalt in der Berichterstattung für maximale Reichweite!',
    description:
      'Steigere deine Reichweite automatisiert durch ein größeres Sportbericht-Portfolio. Durch die Erstellung von Spielberichten über diverse Sportarten sprichst du ein breites Publikum an. Du kannst die Inhalte problemlos auf deiner Website veröffentlichen und über soziale Medien teilen, was nicht nur deine Reichweite erweitert, sondern auch deine Präsenz stärkt und mehr Menschen erreicht.',
    mediaType: 'image',
    mediaSrc: '/assets/reach.jpg',
    btnText: 'Jetzt entdecken',
  },
];
export default platformFeatures;
