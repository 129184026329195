'use client';
import { AddEmailWaitList } from './addEmailWaitList';
import React from 'react';

export const ComingSoon = () => {
  return (
    <div
      className="flex flex-col items-center justify-center mt-10 w-full"
      id="waitlist"
    >
      <h3 className="leading-10 text-4xl font-bold bg-gradient-to-b from-[#D8ECF8] to-[#9BD8FC] inline-block text-transparent bg-clip-text">
        Warteliste
      </h3>
      <AddEmailWaitList />
    </div>
  );
};
