'use client';
import Image from 'next/image';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useScreenWidth } from '@/hooks/useScreenWidth';
import React from 'react';

type TeamMember = {
  name: string;
  title: string;
  image: string;
  twitter?: string;
  linkedin?: string;
  content: string;
  contentMobile: string;
};
const teamMembers = [
  {
    name: 'Leander Quitmann',
    title: 'Co-Founder & CEO',
    image: '/assets/leander.jpg',
    twitter: 'https://twitter.com/@LeanderQuitmann',
    linkedin: 'https://www.linkedin.com/in/leander-quitmann/',
    content:
      'Meine Mission mit BlendLane ist es, durch die Nutzung von KI-generierten Spielberichten für unsere Kunden einen Beitrag zur Entlastung und zur Schaffung von Freiraum für wertschöpfende Tätigkeiten zu leisten. Angesichts des andauernden Kostendrucks auf Unternehmen und des Mangels an Personal bei unseren Kunden sowie der vernachlässigten Berichterstattung über viele Sportarten im Breitensport sehe ich die Notwendigkeit, künstliche Intelligenz als innovative Technologie gezielt und sinnvoll einzusetzen. Durch unsere Dienstleistungen streben wir danach, unseren Kunden bestmöglich zu unterstützen und dazu beizutragen, den Sport in den Medien breiter aufzustellen. Ich freue mich auf diese Reise!',
    contentMobile:
      'Mit BlendLane ist es meine Mission, durch KI-generierte Spielberichte den Kostendruck und Personalmangel unserer Kunden zu mildern, wertschöpfende Aktivitäten zu fördern und eine breitere Medienpräsenz des Sports zu ermöglichen.',
  },
  {
    name: 'Norman Dillthey',
    title: 'Co-Founder & CTO',
    image: '/assets/norman.jpg',
    twitter: 'https://twitter.com/@_itsNorman',
    linkedin: 'https://www.linkedin.com/in/normandilthey/',
    content:
      'Die Idee für BlendLane entstand durch eine Unterhaltung mit einem guten Freund, der als Sportjournalist arbeitet. Ich bin davon überzeugt, dass KI diverse Industrien sehr stark beeinflussen wird. Journalismus ist naheliegend. Schnell war der erste Prototyp erstellt und die Qualität sehr zufriedenstellend. Somit begann die Entwicklung der proprietären BlendLane KI.' +
      'Seit 2022 beschäftige ich mich intensiv mit Künstlichen Intelligenzen, dem fine-tuning und dem Training von individuellen KI Modellen, den Potenzialen der Technologie und den konkreten Anwendungsfällen in der Wirtschaft.' +
      'Neben der KI Entwicklung liebe ich es mich im Bereich Front- und Backend-Entwicklung auszutoben und bastel schon heute immer gerne bereits am nächsten Tool 🙂',
    contentMobile:
      'Durch BlendLane kann ich die wunderbare Welt der Softwareentwicklung und KI mit meiner zweiten Passion, dem Sport, verbinden. Ich bin begeistert mich jeden Tag mit den neuesten Technologien auseinander zu setzen um diese optimal für den Sportjournalistmus einzusetzen.',
  },
];

const Team = () => {
  const { isMobile } = useScreenWidth();
  return (
    <section>
      <div className="px-4 mx-auto lg:px-6">
        <div className="mb-8 lg:mb-16">
          <h2 className="text-4xl md:text-6xl font-bold bg-gradient-to-b from-[#D8ECF8] to-[#66C5FF] text-transparent bg-clip-text">
            Unser Team
          </h2>
          <p className="text-foreground lg:mb-16 sm:text-xl mt-6">
            Wir bei BlendLane sind ein junges Team, das sich der Mission
            verschrieben hat, die Sportberichterstattung zu revolutionieren.
            Unsere Plattform nutzt künstliche Intelligenz und Automatisierung,
            um Sportdaten on-demand in ansprechende Spielberichte zu verwandeln.
            Wir sind stolz darauf, unseren Kunden sofortigen Zugriff auf
            hochwertige Artikel und weitere Features für alle relevanten
            Sportarten zu bieten.
          </p>
        </div>
        <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1">
          {teamMembers.map((member, i) => (
            <div
              key={i}
              className="items-center rounded-lg shadow sm:flex bg-gray-800 border-gray-700"
            >
              <img
                className="mt-6 md:mt-0 mx-auto rounded-lg sm:rounded-none sm:rounded-l-lg h-[256px] w-[256px]"
                src={member.image}
                alt={member.name}
              />
              {!isMobile ? (
                <TooltipProvider key={i}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="p-5">
                        <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {member.name}
                        </h3>
                        <span className="text-gray-500 dark:text-gray-400">
                          {member.title}
                        </span>
                        <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                          {isMobile
                            ? member.contentMobile
                            : member.content.substring(0, 200) + '...'}
                        </p>
                        <ul className="flex space-x-2 sm:mt-0 items-center">
                          {member.twitter && (
                            <li>
                              <a
                                href={member.twitter}
                                target="_blank"
                                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                              >
                                <svg
                                  className="w-5 h-5"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                              </a>
                            </li>
                          )}
                          {member.linkedin && (
                            <li>
                              <a
                                href={member.linkedin}
                                target="_blank"
                                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                              >
                                <svg
                                  className="w-8 h-8"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M18.188 17.426h-2.407v-3.75c0-.9 0-2.055-1.252-2.055s-1.444.979-1.444 1.991v3.814h-2.407V9.675h2.31v1.061h.034a2.53 2.53 0 0 1 2.28-1.252c2.438 0 2.888 1.605 2.888 3.69ZM7.961 8.625a1.399 1.399 0 1 1 1.414-1.406 1.4 1.4 0 0 1-1.414 1.406m1.2 8.809H6.75V9.675h2.407Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>{member.content}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <div className="p-5">
                  <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {member.name}
                  </h3>
                  <span className="text-gray-500 dark:text-gray-400">
                    {member.title}
                  </span>
                  <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                    {isMobile
                      ? member.contentMobile
                      : member.content.substring(0, 200) + '...'}
                  </p>
                  <ul className="flex space-x-2 sm:mt-0 items-center">
                    {member.twitter && (
                      <li>
                        <a
                          href={member.twitter}
                          target="_blank"
                          className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>
                    )}
                    {member.linkedin && (
                      <li>
                        <a
                          href={member.linkedin}
                          target="_blank"
                          className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                        >
                          <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path
                              d="M18.188 17.426h-2.407v-3.75c0-.9 0-2.055-1.252-2.055s-1.444.979-1.444 1.991v3.814h-2.407V9.675h2.31v1.061h.034a2.53 2.53 0 0 1 2.28-1.252c2.438 0 2.888 1.605 2.888 3.69ZM7.961 8.625a1.399 1.399 0 1 1 1.414-1.406 1.4 1.4 0 0 1-1.414 1.406m1.2 8.809H6.75V9.675h2.407Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
