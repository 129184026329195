'use client';

import { useClientScroll } from '@/hooks/useClientScroll';
import { MovingBorderButton } from '@/components/ui/btn-moving-border';
import { useScreenWidth } from '@/hooks/useScreenWidth';
import { Button } from '@/components/ui/button';

interface Props {
  btnText: string;
}

const FeatureButton = ({ btnText }: Props) => {
  const { scrollToElement } = useClientScroll();
  const { isMobile } = useScreenWidth();
  return isMobile ? (
    <Button
      className="self-center"
      onClick={() => scrollToElement('waitlist', 120)}
    >
      {btnText}
    </Button>
  ) : (
    <MovingBorderButton
      borderRadius="1.75rem"
      className="bg-white dark:bg-primary text-white border-neutral-200 dark:border-slate-800"
      onClick={() => scrollToElement('waitlist', 120)}
    >
      Jetzt entdecken!
    </MovingBorderButton>
  );
};

export default FeatureButton;
