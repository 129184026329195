'use client';
import Feature from './Feature';
import platformFeatures from './platformFeaturesData';
import { useEffect } from 'react';

const PlatformFeatures = () => {
  return (
    <div className="flex flex-col gap-20 md:gap-32" id="features">
      {platformFeatures.map((feature, index) => (
        <Feature
          key={feature.title}
          title={feature.title}
          description={feature.description}
          mediaType={feature.mediaType}
          mediaSrc={feature.mediaSrc}
          index={index}
          btnText={feature.btnText}
        />
      ))}
    </div>
  );
};

export default PlatformFeatures;
