'use client';
import { ComingSoon } from '@/components/waitlist/comingSoon';
import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import WaitlistAutoResponse from '@/components/emailTemplate/waitlistAutoResponse';
export default function LandingPage() {
  return (
    <>
      <div className="absolute left-0 right-0 top-0 -z-10 m-auto h-[20vh] w-[50vw] rounded-full bg-crimson opacity-10 blur-[120px]"></div>
      <div className="hero flex flex-col gap-6 items-center" id="home">
        <div className="flex flex-col w-full">
          <h1 className="text-4xl md:text-7xl mt-20 font-bold bg-gradient-to-b from-[#D8ECF8] to-[#66C5FF] text-transparent bg-clip-text">
            Sportspielberichte mit einem Klick.
          </h1>
          <div className="text-4xl md:text-5xl font-bold h-[52.5px] sm:h-auto">
            <span className="text-primary">
              <Typewriter
                words={[
                  'Automatisiert!',
                  'Detaillierte Statistiken!',
                  'KI-generiert!',
                  'On-Demand!',
                  'Jegliche Sportarten!',
                  'Direkt nach Abfiff!',
                ]}
                loop={9999999}
                cursor
                cursorStyle="_"
                typeSpeed={30}
                deleteSpeed={10}
                delaySpeed={1000}
              />
            </span>
          </div>
          <p className="text-md md:text-2xl text-foreground mt-8">
            <span className="">Entdecke</span> mit BlendLane die Zukunft des
            Sportjournalismus. Unsere Plattform nutzt künstliche Intelligenz und
            Automatisierung, um Sportdaten on-demand in ansprechende
            Spielberichte zu verwandeln. Du erhälst sofortigen Zugriff auf
            hochwertige Artikel und weitere Features für alle relevanten
            Sportarten.
          </p>
        </div>
        {/*<div className="mt-12">*/}
        {/*  <ComingSoon />*/}
        {/*</div>*/}
      </div>
    </>
  );
}
