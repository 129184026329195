export const useClientScroll = () => {
  const scrollToElement = (elementId: string, top: number = 102) => {
    const elementToScrollTo = document.getElementById(elementId);
    if (elementToScrollTo) {
      const yOffset = -top;
      const y =
        elementToScrollTo.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      console.warn(`${elementId} not found.`);
    }
  };

  const scrollToBottom = (isSmooth: boolean = false) => {
    window.scrollTo({
      top: document.body.scrollHeight + 500,
      ...(isSmooth ? { behavior: 'smooth' } : {}),
    });
  };

  return {
    scrollToElement,
    scrollToBottom,
  };
};
