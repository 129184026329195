'use client';
import { CheckIcon, EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { Button, ButtonProps } from '@/components/ui/button';
import React, { useEffect, useState } from 'react';

export const SendMailButton = ({
  loading,
  submitted,
  className,
  type = 'button',
}: {
  loading: boolean;
  submitted: boolean;
  className?: string;
  type: ButtonProps['type'];
}) => {
  const [showEnvelopeAgain, setShowEnvelopeAgain] = useState(false);
  return (
    <Button
      disabled={loading}
      isLoading={loading}
      type={type}
      className={className}
    >
      {!loading && !submitted && <EnvelopeClosedIcon className="mr-2" />}
      {submitted && <CheckIcon className="mr-2" />}
      Senden
    </Button>
  );
};
