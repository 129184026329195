'use client';
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { WaitlistFormData } from '@/types/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { waitlistSchema } from '@/components/waitlist/waitlistSchema';
import {
  sendMail,
  sendSlackMessage,
  sendWaitlistAutoResponse,
  waitlistSignup,
} from '@/utils/actions';
import { SendMailButton } from '@/components/sendMailButton';

export function AddEmailWaitList() {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WaitlistFormData>({ resolver: zodResolver(waitlistSchema) });

  const onSubmit: SubmitHandler<WaitlistFormData> = async (
    data: WaitlistFormData
  ) => {
    if (!data) {
      setErrorMessage('Bitte gib deine Email Adresse an');
      return;
    }
    setLoading(true);
    setSuccessMessage('');

    console.log(`email:${data.email}`, typeof data.email);
    try {
      await waitlistSignup(data.email);
      setSuccessMessage('Wilkommen an Board. Wir melden uns bei dir!');
      setLoading(false);

      setSubmitted(true);
      await sendWaitlistAutoResponse(data.email);
    } catch (e) {
      setErrorMessage(
        'Leider konnten wir deine E-Mail nicht speichern. Bitte sende uns eine E-Mail an: '
      );
      setLoading(false);
    }
    await sendSlackMessage(`New Email: ${data.email}`, 'waitlist');
  };

  return (
    <div className="relative flex flex-col items-center justify-center antialiased w-full h-full">
      <div className="max-w-md mx-auto">
        <p className="max-w-lg mx-auto my-2 text-md text-center relative text-foreground">
          Wir arbeiten mit Hochdruck daran unsere Plattform zu launchen. Trage
          dich gerne in unsere Warteliste ein und wir melden uns bei dir, sobald
          wir ready sind.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center"
        >
          <input
            type="email"
            placeholder="Your Email"
            className="rounded-lg p-2 outline-0 w-full relative mt-4 text-black bg-white placeholder:text-neutral-700"
            {...register('email')}
          />
          {errors.email && (
            <span className="mt-2 text-md text-cardinal-red">
              {errors.email.message}
            </span>
          )}
          {/*<input type="submit" />*/}
          {/*<Button type="submit" className="mt-4" loading={loading}>*/}
          {/*  Meldet Euch!*/}
          {/*</Button>*/}

          <SendMailButton
            className="mt-4"
            loading={loading}
            submitted={submitted}
            type="submit"
          />
          {successMessage && (
            <p className="mt-2 text-green-500">{successMessage}</p>
          )}
          {errorMessage && (
            <div className="mt-2 text-red-500">
              {errorMessage}
              <a
                className="underline underline-offset-4"
                href="mailto:leander@blendlane.com"
              >
                leander@blendlane.com
              </a>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
