'use client';
import { useClientScroll } from '@/hooks/useClientScroll';
import { clsx } from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import { Button } from './ui/button';

export const NavInternalLink = ({
  btnText,
  elementToScrollTo,
  className,
  asBtn = false,
  underline = false,
  top = 120,
}: {
  btnText: string;
  elementToScrollTo: string;
  className?: string;
  top?: number;
  underline?: boolean;
  asBtn?: boolean;
}) => {
  const pathName = usePathname();
  const { scrollToElement } = useClientScroll();

  if (pathName !== '/') {
    const homePath = 'home';
    if (elementToScrollTo === homePath) {
      return !asBtn ? (
        <a
          href="/"
          className={clsx(
            'font-light text-left cursor-pointer',
            underline ? 'hover:underline hover:underline-offset-2' : '',
            className
          )}
        >
          {btnText}
        </a>
      ) : (
        <Button variant="default" size="default">
          {btnText}
        </Button>
      );
    }
    return !asBtn ? (
      <a
        href={`/#-to${elementToScrollTo}`}
        className={clsx(
          'font-light text-left cursor-pointer',
          underline ? 'hover:underline hover:underline-offset-2' : '',
          className
        )}
      >
        {btnText}
      </a>
    ) : (
      <Button variant="default" size="default">
        {btnText}
      </Button>
    );
  }

  return !asBtn ? (
    <a
      className={clsx(
        'font-light text-left cursor-pointer',
        underline ? 'hover:underline hover:underline-offset-2' : '',
        className
      )}
      onClick={() => scrollToElement(elementToScrollTo, top)}
    >
      {btnText}
    </a>
  ) : (
    <Button
      variant="default"
      size="default"
      onClick={() => scrollToElement(elementToScrollTo, top)}
    >
      {btnText}
    </Button>
  );
};
