import(/* webpackMode: "eager", webpackExports: ["NavInternalLink"] */ "/vercel/path0/components/NavInternalLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/platformFeatures/FeatureButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/platformFeatures/PlatformFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StreamText"] */ "/vercel/path0/components/streamText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Team.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ComingSoon"] */ "/vercel/path0/components/waitlist/comingSoon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/sites/landingpage.tsx");
