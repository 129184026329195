import Image from 'next/image';
import FeatureButton from '@/components/platformFeatures/FeatureButton';
import clsx from 'clsx';
import { StreamText } from '@/components/streamText';

interface Props {
  title: string;
  description: string;
  mediaType: string;
  mediaSrc: string;
  index: number;
  btnText: string | null;
}
const Feature = ({
  title,
  description,
  mediaType,
  mediaSrc,
  btnText,
  index,
}: Props) => {
  return (
    <>
      <div
        className={`relative flex flex-col gap-6 justify-between items-center md:items-start ${
          index % 2 !== 0 ? 'md:flex-row-reverse' : 'md:flex-row md'
        }`}
      >
        <div
          className={clsx(
            'h-20 w-20 absolute -translate-y-1/3 bg-pine-green opacity-90 blur-[100px]',
            { [index % 2]: 'left-20', [index % 2]: 'right-40' }
          )}
        ></div>
        <div
          className={`max-w-xl md:w-2/3 flex flex-col items-start gap-11  ${
            index % 2 !== 0 ? 'ml-auto' : ''
          }`}
        >
          <h2
            className={clsx(
              'text-2xl md:text-5xl  font-bold bg-gradient-to-b from-[#D8ECF8] to-[#66C5FF] text-transparent bg-clip-text'
            )}
          >
            {title}
          </h2>
          <div className="md:text-xl max-w-2xl text-foreground">
            {description.split('\n').map((text, i) => {
              return (
                <p className="text-foreground" key={i}>
                  {text}
                </p>
              );
            })}
          </div>
          {btnText && <FeatureButton btnText={btnText} />}
        </div>

        {/*<CardStackDemo />*/}
        {/*{index === 0 ? (*/}
        {/*  <CardStackDemo />*/}
        {/*) : (*/}
        <div className="md:w-1/3">
          {mediaType === 'image' ? (
            <Image
              className="w-auto h-auto"
              width={437}
              height={355}
              src={mediaSrc}
              alt={title}
            />
          ) : (
            <StreamText speed={80} />
          )}
        </div>
        {/*)}*/}
      </div>
      {/*<hr className="border-t border-border mb-6" />*/}
    </>
  );
};

export default Feature;
//
// const CARDS = [
//   {
//     id: 0,
//     name: 'BHC vs. HSV',
//     designation: '(c) BlendLane',
//     content: (
//       <div>
//         <p>
//           Verrückte Schlussphase!{' '}
//           <Highlight>
//             Bergischer HC bezwingt HSV Hamburg in letzter Sekunde
//           </Highlight>
//         </p>
//         <p className="mt-4">
//           Das Bundesligaspiel zwischen dem Bergischen HC und dem HSV Hamburg bot
//           Spannung bis zum Schlusspfiff. In einer engen Partie...
//         </p>
//       </div>
//     ),
//   },
//   {
//     id: 1,
//     name: 'THW Kiel vs. Bergischer HC',
//     designation: '(c) BlendLane',
//     content: (
//       <div>
//         <p>THW Kiel gewinnt souverän mit 39:30 gegen Bergischen HC.</p>
//         <p>
//           Das Spiel zwischen dem THW Kiel und dem Bergischen HC in der Wunderino
//           Arena endete mit 39:30 für die Gastgeber.
//         </p>
//       </div>
//     ),
//   },
// ];
